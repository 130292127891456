// Libraries 
import React, { useState, useEffect, createRef } from "react";
import { View, Text } from 'react-native';
import DatePicker, { setDefaultLocale, CalendarContainer, registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/inputcss.css";
import { nl } from "date-fns/locale";
import moment from 'moment';

// Components
import { TextInputDefault } from '../components/textInput';
import IconSetter from '../components/iconSetter';

// Styles
import FncColors from '../styles/colors';
let Colors = [];

moment.locale('nl');
registerLocale('nl', nl);
setDefaultLocale('nl');

const DateInput = (props) => {
    Colors = FncColors();
    const [startDate, setStartDate] = useState('');
    const [placeholder, setPlaceholder] = useState('Datum');
    const onChangeText = props.onChangeText;
    const style = props.style;
    const index = props.index;
    const tablet = props.tablet;
    const mobile = props.mobile;
    const cacheValue = props.cacheValue;
    const showInputVar = props.showInputVar;
    const hideInput = props.hideInput;
    const showInput = props.showInput;
    const stap = props.stap;
    const maxWidth = props.maxWidth;

    const dateRef = createRef();
    let heightBool = true

    useEffect(() => {
        setPlaceholder(cacheValue);
    },[])

    if (!showInputVar && index === 1) {
        return null;
    } else {

    return (<View ref={dateRef} style={{ width: maxWidth, marginRight: 20, marginTop: 5}}>
        { index === 0 ? 
        <DatePicker
        locale='nl'
        dateFormat="dd-mm-yyyy"
        value={startDate}
        onCalendarClose={ mobile || tablet ? ()  => {heightBool = true} : ()  => {heightBool = true}}
        wrapperClassName={'datepickerWrapper'}
        placeholderText='Datum'
        popperContainer={(e) => {
            if(e.children){
                heightBool = false;
            } else {
                heightBool = true;
            }
            return (
            <View style={{ backgroundColor: 'transparent', zIndex: 100, minHeight: heightBool ? 1 : 300, width: '100%'}}>{e.children}</View>
            )
        }}
        selected={startDate}
        onChange={(date) => [setStartDate(date), onChangeText(moment(date).format('DD-MM-YYYY'))]} customInput={
            <View style={{ width: '100%'}} className="react-datepicker-ignore-onclickoutside">
            <TextInputDefault
                tablet={tablet}
                input={moment(startDate).format('DD-MM-YYYY')}
                placeholder={'Datum'}
                style={style}
                placeholderTextColor={Colors[9]}
                returnKeyType="next"
            />
            { startDate === '' ? <View style={{ backgroundColor: 'white', width: 100, height: 30, position: 'absolute', top: 15, left: 5, justifyContent: 'center'}}><Text style={{ paddingLeft: 5}}>{placeholder}</Text></View> : null}
                <View style={{ position: 'absolute', top: 20, right: 15}}> 
                    <IconSetter family='FontAwesome' name='calendar-o' size={16} color={Colors[9]} />
                </View>
            </View>}
        /> : <DatePicker
        locale='nl'
        dateFormat="dd-mm-yyyy"
        placeholderText='Datum'
        wrapperClassName={'datepickerWrapper'}
        value={startDate}
        onCalendarClose={ mobile || tablet ? ()  => {heightBool = true} : ()  => {heightBool = true}}
        selected={startDate}
        popperContainer={(e) => {
            if(e.children){
                heightBool = false;
            } else {
                heightBool = true;
            }
            return (
            <View style={{ backgroundColor: 'transparent', zIndex: 100, minHeight: heightBool ? 1 : 300, width: '100%'}}>{e.children}</View>
            )
        }}
        onChange={(date) => [setStartDate(date), onChangeText(moment(date).format('DD-MM-YYYY'))]} customInput={<View>
            <TextInputDefault
                tablet={tablet}
                input={moment(startDate).format('DD-MM-YYYY')}
                placeholder={'Datum'}
                style={style}
                placeholderTextColor={Colors[9]}
                returnKeyType="next"
            />    
            { startDate === '' ? <View style={{ backgroundColor: 'white', width: 100, height: 30, position: 'absolute', top: 15, left: 5, justifyContent: 'center'}}><Text style={{ paddingLeft: 5}}>{placeholder}</Text></View> : null}         
            <View style={{ position: 'absolute', top: 20, right: 15 }}>
            <IconSetter family='FontAwesome' name='calendar-o' size={16} color={Colors[9]} />
        </View>
    </View>}
        />}

    </View>
    )};
};

export default DateInput;