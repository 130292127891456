import React from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";

import FncColors from '../styles/colors';
let Colors = [];


export default function Loader( props ) {
  Colors = FncColors();

  return (
    <View style={[styles.container, styles.horizontal, {backgroundColor: Colors[12]}]}>
      <ActivityIndicator size="large" color={Colors[2]} />
    </View>
  );

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center"
  },
  horizontal: {
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 10
  }
});
