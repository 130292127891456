// Libraries
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useWindowDimensions } from "react-native";
import { APP_URL, API_URL } from '@env';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// components
//import { SET_ALL as SET_ALL_COMMON } from '../context/actions/commonActions';
import { setData, getData, removeData } from '../functions/storage';
import { SET_PARAM, SET_PARAM_CACHE } from '../context/actions/commonActions';

// Functions

const firebaseConfig = {
  apiKey: "AIzaSyDRoqnf613BoiMTVzJ3X26LXGvzA5_-f_E",
  authDomain: "adviesopmaat-8f7eb.firebaseapp.com",
  projectId: "adviesopmaat-8f7eb",
  storageBucket: "adviesopmaat-8f7eb.appspot.com",
  messagingSenderId: "199090891391",
  appId: "1:199090891391:web:c0fa3373213b96c5c7ed4a",
  measurementId: "G-P923DWM6RH"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


export const arrange_global_settings = () => {
  global.url = window.location.href;
  global.settings = specific_settings;
  global.settings.inFrame = inFrame();
  if( global.settings.inFrame && global.settings.app_url != 'https://toolspartners.milieucentraal.nl' ){
    global.settings.show_header_overzicht--;
    global.settings.show_footer_overzicht--;
    global.settings.show_header_start--;
    global.settings.show_footer_start--;
    global.settings.show_header_inspiration--;
    global.settings.show_footer_inspiration--;
    global.settings.show_header_vraag--;
    global.settings.show_footer_vraag--;
    global.settings.show_header_advies--;
    global.settings.show_footer_advies--;
    global.settings.show_progress_bar--;
  }

  //console.log(global.settings);
}

export const get_module_naam = (props) => {
  // als er in config.js een vaste module is ingesteld
  // dan gebruiken we die, maakt niet uit welke url
  // er gebruikt wordt
  let module_naam = global.settings.force_module;
  if( module_naam !== undefined  && module_naam !== "") return module_naam;

  module_naam =get_property( props, "route.params.module", "");
  return module_naam;
}


export const get_property = (obj, path, def) => {
  if(path.includes('.')){
    if(obj.hasOwnProperty(path.split('.')[0])) {
      return get_property(obj[path.split('.')[0]], path.split('.').slice(1).join('.'), def);      
    }
    else {
      return def;
    }
  }
  else {
    if (typeof obj !== 'object' || obj === null) {
      return def;
    }
    else {
      if (obj.hasOwnProperty(path) ) {
        return obj[path];
      }
      else{
        return def;
      }
    }
  }
}


export const get_url_param = ( nr, def ) => {

  var arr_url = window.location.href.split("/");

  if ( arr_url.length - 3 < nr ) return def;

  return arr_url[ nr + 2 ];
}


export const checkOutdatedCache = async () => {
  const timestamp = await AsyncStorage.getItem('timestamp');
  // console.log(timestamp);
  var now = moment(new Date()).valueOf();
  var then = timestamp;
  var maxtime = global.settings.cache_lifetime_hours * 60 * 60 * 1000;
  if (timestamp) {
    if ((now - then) >= maxtime) {
      clearAppData( true );
    }
    else {
      await AsyncStorage.setItem('timestamp', now);
    }
  } else {
    await AsyncStorage.setItem('timestamp', now);
  }
};

export const clearAppData = async ( reload ) => {
    try {
        const keys = await AsyncStorage.getAllKeys();
        await AsyncStorage.multiRemove(keys);
        //if(reload) location.replace("/");
        if(reload) location.reload();
    } catch (err) {
        console.error('Error clearing app data');
    }
}

export const is_mobile = () => {
  const { width } = useWindowDimensions();

  if(width < 551) return true;

  return false;
}

export const is_tablet = () => {
  const { width } = useWindowDimensions();

  if(width <= 1024) return true;

  return false;


  const userAgent = navigator.userAgent.toLowerCase();
  const tablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
  return tablet;
}



export const cleanup_url = (url) => {
  var tmp = url.split("?")[0];
  tmp = tmp.split(":")[1];
  tmp = tmp.split("/")[2];

  return tmp;
}


//Opent de url in een nieuw tablad, als deze url geen # is
export const openUrlNewTab = (url) => {
  if(url == "#"){
    return false;
  }
  window.open(url, "_blank");
}

//Console log functie zodat je op 1 plek alle console.log's uit kan zetten
export const customConsoleLog = (log) => {
  // api url log > 0
  // console.log(log);
}

export const sendGa = (module_naam, page_view) => {

  // Google Analytics UA
  if(global.settings.google_page_name){
    module_naam = settings.google_page_name; 
  }

  //console.log('google', module_naam + "/" + page_view);
  ga('send', 'pageview', module_naam + "/" + page_view);

  // Google Analytics 4
  logEvent(analytics, 'pageview', {
    module: module_naam,
    page: module_naam + "/" + page_view
  });
}

export const sendGaButton = ( module_naam, name ) => {
  // Google Analytics 4
  if(global.settings.google_page_name){
    module_naam = settings.google_page_name; 
  }

  logEvent(analytics, 'buttonclick', {
    module: module_naam,
    button: module_naam + "/" + name
  });
}

//Sluit de custom dropdown als je er buiten klikt
export const clickedOutsideView = (ref, setDropDown, onblur) => {
    useEffect(() => {
        /**
         * Sluit de dropdown als hij open staat en je klikt erbuiten
         */
        const handleClickOutside = (event) => {
          if(ref.current && !ref.current.contains(event.target)){
            //alert("You clicked outside of me!");
            if(onblur){
              onblur()
            }else {
            setDropDown(false);
            }
          }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export const hoverOutsideCard = (ref) => {
  useEffect(() => {
      /**
       * Sluit de dropdown als hij open staat en je klikt erbuiten
       */
      const handleClickOutside = (event) => {
        console.log("over");
        /*if(ref.current && !ref.current.contains(event.target)){
          //alert("You clicked outside of me!");
          //setDropDown(false);
        }*/
      }

      // Bind the event listener
      document.addEventListener("mouseover", handleClickOutside);
      /*return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mouseover", handleClickOutside);
      };*/
  }, [ref]);
}

//Laat de input wel of niet zien aan de hand van de status, voor nu altijd laten zien.
export const decideCSS = (key, statusData) => {
  const status = statusData[key];
  // console.log("css: " + status);
  if (status == "invisible") {
    //return {display: "none"};
    return { opacity: 0.7 };
  }
  return { display: "block" };
}

//Enable of disable de input aan de hand van de status.
export const decideEnable = (key, statusData) => {
  const status = statusData[key];
  // console.log("status: " + status);
  if (status == "disabled" || status == "invisible") {//Tijdelijk hier op status invisible checken
    return false;
  }
  return true;
}

export const decideShow = (key, statusData) => {
  const status = statusData[key];
  //console.log("show: " + status);
  if (status == "invisible") {
    return false;
    //return {opacity: 0.7};
  }
  return true;
}

export const stripFirstColon = (value) => {
  //console.log(value);
  const placeholder = value.split(":");
  if(placeholder.length >= 2){
    placeholder.shift();
    let label = placeholder.join();
    return label;
  }
  return value;
}

export const verwerkData = (paramData, stapId, vraagtype, antObject, commonDispatch, first, exclusief = null, kov, moduleNaam, vervolgstapId) => {
  //Haal de data op uit de asyncStorage en pas deze aan
  getData("param_cache", paramData)
  .then( param => {
    const chain = param.chain;
    // console.log(chain);

    //Bepaal wat de index is van de stap die je aanpast
    const objectIndex = chain.findIndex((obj => obj.stap_id == stapId));

    //Stap id komt al voor, updaten
    if(objectIndex != "-1"){
      Object.keys(antObject).map((antwoordId, index) => {
        antwoordId = parseInt(antwoordId);

        const dataAntwoordIds = chain[objectIndex].antwoord_id;
        let newArrayAntwoordIds = [];

        //Als het antwoord ID al voorkomt en het is exclusief, dan uitzetten
        
        if(exclusief == 1){
          newArrayAntwoordIds = [antwoordId];
        }        
        else{
          newArrayAntwoordIds = arrayUnique([...dataAntwoordIds, antwoordId]);
        }
        //console.log(newArrayAntwoordIds);

        const antwoord = antObject[antwoordId];

        //Check wat het vraagtype is
        if(vraagtype == 'mc_multi'){//checkbox
          let antwoordIdIndex = newArrayAntwoordIds.indexOf(antwoordId);
          if(antwoord == 0){
            newArrayAntwoordIds.splice(antwoordIdIndex, 1);
          }

          chain[objectIndex].antwoord_id = newArrayAntwoordIds;
        }
        else if(vraagtype == 'mc_enkel'){//radio button, mag maar 1 antwoord id zijn
          chain[objectIndex].antwoord_id = [antwoordId];
        }
        else{
          chain[objectIndex].antwoord_id = newArrayAntwoordIds;

          let objectIndexAntwoord = chain[objectIndex].antwoord_id.indexOf(antwoordId);
          chain[objectIndex].antwoord[objectIndexAntwoord] = antwoord;
          // console.log('antwoord3', antwoord);
        
        }
      });
      param.chain = chain;
      param.moduleNaam = moduleNaam;
      // console.log(chain);

    }
    else{//Nieuw
      let antwoordIds = [];
      let antwoorden = [];
      Object.keys(antObject).map((antwoordId, index) => {
        antwoordId = parseInt(antwoordId);
        antwoordIds.push(antwoordId);

        //Bij deze types altijd antwoorden toevoegen
        if(vraagtype == 'open_multi' || vraagtype == 'open_enkel'){
          antwoorden.push(antObject[antwoordId]);
        }
      });

      let chain_array = [...chain, {stap_id: stapId, type: vraagtype, antwoord_id: antwoordIds, vervolgstapId: vervolgstapId}]; 
      if(antwoorden.length > 0){
        chain_array = [...chain, {stap_id: stapId, type: vraagtype, antwoord_id: antwoordIds, antwoord: antwoorden, vervolgstapId: vervolgstapId}];
      }
      // console.log(chain_array);
      param.chain = chain_array;
      param.moduleNaam = moduleNaam;
    }
    
    if(first || kov){
      //console.log("Data setten met param: " + JSON.stringify(param));
     // console.log("verwerkData");
      commonDispatch( { type: SET_PARAM_CACHE, value: param } );      
    }
  });
}

const arrayUnique = (array) => {
    var a = array.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
}

export const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
}

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
}

//Voeg of iets toe aan de chain
/*export const verwerkChain = (param, stap_id, vraagtype, antwoord_ids, commonDispatch, antwoorden = null) => {
  let chain_array = [];
  const chain = param.chain;
  //console.log(chain);

  //Als het stap_id al voorkomt in de chain, dan bijwerken, anders nieuw toevoegen
  const stap_exists = ifObjectValueExists(chain, stap_id, "stap_id");
  if(stap_exists){
    //console.log("bestaat al");
    if(antwoorden != null){
      chain_array = [{stap_id: stap_id, type: vraagtype, antwoord_id: antwoord_ids, antwoord: antwoorden}];
    }
    else{
      chain_array = [{stap_id: stap_id, type: vraagtype, antwoord_id: antwoord_ids}];
    }
  }
  else{
    //console.log("bestaat niet");
    if(antwoorden != null){
      chain_array = [...chain, {stap_id: stap_id, type: vraagtype, antwoord_id: antwoord_ids, antwoord: antwoorden}];
    }
    else{
      chain_array = [...chain, {stap_id: stap_id, type: vraagtype, antwoord_id: antwoord_ids}];        
    }
  }
  console.log("chaindata: " + JSON.stringify(chain_array));
  param.chain = chain_array;
  //const test = {...param};
  //console.log(param);
  
  commonDispatch( { type: SET_PARAM, value: param } );
}*/

export const nextScreen = (param, vervolgstap, commonDispatch, navigation, moduleNaam) => {    
  //Pas de vervolgstap aan en sla de param weer op
  param.goto = vervolgstap;
  param.moduleNaam = moduleNaam;
  commonDispatch({ type: SET_PARAM, value: param });

  console.log("volledige param: " + JSON.stringify(param));
  navigation.push('Home', { oldParam: oldParam });
}

//Check of er waarde bij een name al voorkomt in een object
export const ifObjectValueExists = (array, value, name) => {
  return array.some(function(el) {
    return el[name] === value;
  }); 
}

// Waarde van een useState variabele krijgen als dit vanuit een useEffect gevonden moet worden
export const __getCurrentHookValue = async (setHookFunction) => {
  return new Promise((resolve) => {
    setHookFunction(prev => {
      resolve(prev)
      return prev;
    })
  })
};


// vervangen van variabelen in teksten
// alles tussen [] wordt vervangen door de mee gegeven variabeln
// op volgorde waarin ze staan
export const textReplace = ( text, arrVars ) => {

  let newText = "";
  let tmpArr = text.split('[');

  for ( var i = 0; i < tmpArr.length; i++ ){
    let tmp = tmpArr[i];
    // de rest van de placeholder verwijderen
    let n = tmp.indexOf(']');
    if (n) tmp = tmp.substr( n + 1 ); 

    if ( i == tmpArr.length - 1){
      //laatste element, niets meer toevogen
      newText += tmp;
    }
    else {
      if( i < arrVars.length ){
        newText += tmp + arrVars[ i ];
      }
      else {
        newText += tmp + '[ onvoldoende variabelen ]';        
      }
    }
  }
  return newText;
}


// ophalen van de algemene gegevens op basis van het deviceId en language
// gegevens worden in de Context geplaatst
export const commonData = ( {deviceId, language, commonDispatch, common, hasPermissions } ) => {
 
  setData( 'language', language );

  var timeStamp = common.timeStamp || '';
  var url = API_URL + '/common?language_code=' + language + '&device=' + deviceId + '&timestamp=' + timeStamp;
  fetch( url )
    .then(response => response.json())
      .then(json => {
        if( json.success ){
          let data = json.data.updates;
          let results = {
            language: language,
            languages: data.languages.length > 0 ? data.languages : common.languages,
            deviceId: deviceId,
            permissions: hasPermissions,
            timeStamp: json.data.timestamp,
            themes: data.themes.length > 0 ? data.themes : common.themes,
            text: data.text.length > 0 ? adjustHelpTekst( data.text ) : common.text,
            faqs: data.faqs.length > 0 ? adjustFaq( data.faqs ) : common.faqs,
            tutorials: data.tutorials.length > 0 ? data.tutorials : common.tutorials,
            poiTypes: data.poi_types.length > 0 ? data.poi_types : common.poiTypes
          }
          commonDispatch( { type: SET_ALL_COMMON, value: results } );
        }
        else {
          console.log( "fout met ophalen common gegevens");
        }
      });        
}

// van deze array, maken we een object
// gebaseerd op scherm.onderdeel
const adjustHelpTekst = ( text ) => {

  var obj = {};

  for ( var i=0; i < text.length; i++ ) {
    let screen = text[i].txt_screen;
    let name = text[i].txt_name;
    let val = text[i].txd_text;

    var scr = { ...obj[screen], [name]: val }
    obj = { ...obj, [screen]: scr };
  }

  return obj;
}

// van deze array, maken we een object
export const adjustWebTekst = ( text ) => {
  var obj = {};

  for ( var i=0; i < text.length; i++ ) {
    let name = text[i].wtt_name;
    let val = text[i].wtd_html;

    obj = { ...obj, [name]: val };
  }

  return obj;
}

export const readProperty = ( object, name ) => {
  if ( object.hasOwnProperty( name ) ){
    return object[name];
  }
  else {
    return '{' + name + '}';
  }
}

const adjustFaq = ( faq ) => {

  var newArray = [];

  for( var i = 0; i < faq.length; i++ ){
    let item = faq[i];
    newArray.push( { question: item.fdl_title, answer: item.fdl_description } );
  }

  return newArray;  
}

// maakt een array met delen van de oorspronkelijke
// array met maximaal het aantal aangegeven elementen
export const arrangeArray = (array, maxElements) => {

  var arrayIn = [ ...array, array[0] ];
  var newArray = [];

  for( var i = 0; i < arrayIn.length; i+=maxElements){
    newArray.push(arrayIn.slice(i, i + maxElements))
  }

  return newArray;
}


// breidt de url eventueel uit met de basis url voor de server
// mag een enkele uri zijn of een array
export const adjustUri = ( uri ) => {

  if ( !Array.isArray( uri ) ){
    return adjustUriHelper ( uri );
  }

  // Het is een array, dus alle elementen aanpassen
  for ( var i = 0; i < uri.length; i++ ){
    uri[i] = adjustUriHelper ( uri[i] );
  }
  return uri;
}

export const vergelijk_datums = (datum, datum_check, check = 'max') => {
  //console.log(datum);
  //console.log(datum_check);

  datum = datum.split('-');
  datum_check = datum_check.split("-");


  const nieuw_datum = new Date(datum[2] + "-" + datum[1] + "-" + datum[0]);
  const nieuw_datum_check = new Date(datum_check[0] + "-" + datum_check[1] + "-" + datum_check[2]);

  const datum_formatted = datum_check[2] + "-" + datum_check[1] + "-" + datum_check[0];
  if(check == 'max'){
    console.log(nieuw_datum);
    console.log(nieuw_datum_check);
    if(nieuw_datum > nieuw_datum_check){
        alert("Let op: maximale datum is " + datum_formatted);
        return false;
    }
    return true;
  }

  if(check == 'min'){
    if(nieuw_datum < nieuw_datum_check){
        alert("Let op: minimale datum is " + datum_formatted);
        return false;
    }
    return true;
  }
  return true;
}

// alleen voor interne aaanroepen
// past een enkele uri aan
const adjustUriHelper = ( uri ) => {
  // controleren of het een object is
  if ( typeof uri === 'object' ){
    uri = uri.img_url;
  }
  // als uri al kompleet is, deze terugsturen
  if ( uri.toLowerCase().startsWith( APP_URL ) ) return uri;
  if ( uri.toLowerCase().startsWith( 'file' ) ) return uri;

  // checken of uri met / begint
  if ( !uri.startsWith( '/') ) uri = "/" + uri;APP_URL
  // basis url toevoegen
  return APP_URL + uri;
}
